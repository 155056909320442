import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Banner from "../components/banner";
import IndividualPlan from "../components/individualPlan";
import NumbersWithCount from "../components/numbersWithCount";
import RecentNews from "../components/recentNews";
import Slider from "../components/slider";

import Image from "../assets/img/hero-img.png";

const IndexPage = ({ data: lastPosts }) => {
  const lang = "pl";

  return (
    <Layout
      seoTitle="Strona główna"
      isHome={true}
      lang={lang}
      translationEN="/en/"
      translationDE="/de/"
    >
      <Banner
        type="home"
        title="Dostawy palet<br /> w Europie <br />zawsze na czas"
        image={Image}
        lang={lang}
      />

      <IndividualPlan
        title="Indywidualne plany produkcji"
        text="PalettenWerk oferuje usługi w zakresie projektowania i produkcji palet specjalnego przeznaczenia, w tym palet wielkogabarytowych, podestów i platform drewnianych do przewożenia maszyn czy sprzętu AGD, a także palet spełniających specjalne warunki w zakresie wytrzymałości konstrukcji, systemów zabezpieczeń ładunku oraz wszelkich innych szczegółów wykonania poszczególnych elementów składowych palety."
        lang={lang}
      />
      <section className="offer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">Oferta</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-80">
                Oferta PalettenWerk obejmuje europalety EPAL, palety chemiczne
                CP oraz ponad 5 tys. wzorów palet cztero i dwuwejściowych
                zaprojektowanych zgodnie z normami technicznymi lub specyfikacją
                Klienta. PalettenWerk dostarcza palety dla firm między innymi z
                branży spożywczej, budowlanej, chemicznej, farmaceutycznej,
                browarniczej i metalowej.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 offer__btn-holder">
              <Link className="btn" to="/oferta/" data-hover="Zobacz więcej">
                Zobacz więcej
                <span className="btn-arrows"></span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <NumbersWithCount lang={lang} />
      <RecentNews lang={lang} lastPosts={lastPosts} />
      <Slider lang={lang} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    lastPosts: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { code: { eq: PL } } }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          uri
          title
          content
          excerpt
          date
          language {
            code
            slug
            name
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
